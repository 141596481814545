<template>
  <div class="filter-menu-dropdown card">
    <header class="card-header">
      <p class="card-header-title has-text-info">
        <span class="icon">
          <i class="fal fa-calendar-alt" />
        </span>
        <span>Registration year</span>
      </p>
      <a class="card-header-icon" aria-label="close" @click="clearMenu">
        Close
      </a>
    </header>
    <hr class="hr is-marginless" />
    <div class="card-content">
      <div class="columns is-multiline" v-if="hasAggs">
        <div
          class="column is-narrow"
          v-for="(type, index) in aggs"
          :key="index"
        >
          <div class="radio-button">
            <checkbox
              name="values"
              v-model="values"
              :value="type.key"
              :disabled="loading"
            >
              <span
                class="button is-capitalized"
                :class="itemClass(type.key).count"
                >{{ type.key }}</span
              >
            </checkbox>
          </div>
        </div>
      </div>
      <p class="has-text-grey-light" v-else>No filter options available</p>
    </div>
    <footer class="card-footer">
      <div class="column is-12">
        <div class="columns">
          <div class="column">
            <a class="has-text-danger" v-if="hasSelected" @click="clearFilter"
              >Reset</a
            >
            <label class="has-text-grey-light" v-else>Reset</label>
          </div>
          <div class="column is-narrow">
            <p v-if="itemCount">{{ itemCount }} items selected</p>
            <label class="has-text-grey-light" v-else>No items selected</label>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { includes } from 'lodash'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'SimilarListingsYearFilter',
  computed: {
    ...mapGetters({
      aggs: 'companion/similarListings/filterBy/year',
      filter: 'companion/similarListings/filterBy/yearFilter',
      loading: 'companion/similarListings/loading'
    }),
    values: {
      get() {
        return this.filter
      },
      set(values) {
        this.setFilter({ year: values })
      }
    },
    itemCount() {
      return this.values.length
    },
    hasSelected() {
      return this.itemCount
    },
    hasAggs() {
      return this.aggs && this.aggs.length > 0
    }
  },
  methods: {
    isSelected(key) {
      return includes(this.values, key) || !this.hasSelected
    },
    itemClass(key) {
      return {
        count: [
          [
            this.hasSelected && this.isSelected(key)
              ? 'is-soft'
              : 'is-grey is-outlined'
          ]
        ]
      }
    },
    clearFilter() {
      this.setFilter({ year: [] })
    },
    ...mapActions({
      setFilter: 'companion/similarListings/filterBy/updateFilter'
    }),
    ...mapMutations({
      clearMenu: 'companion/similarListings/filterBy/clearMenu'
    })
  }
}
</script>
